import { handleComparableLocationsCounter } from './topBarHelper';


export const updateComparables = (locationId, siteKey, kind, bounceFunction) => {
  const locationsLimit = 4;
  const locationIdStr = `${locationId}`;
  const comparableLocations = (localStorage && JSON.parse(localStorage.getItem(`my.comparable_lease_${siteKey}`))) || {};
  const comparableCount = comparableLocations && Object.keys(comparableLocations).length;
  const locationKindPresent = comparableCount && comparableLocations[kind];
  const locationIsInTheList = locationKindPresent && comparableLocations[kind].indexOf(locationIdStr) >= 0;
  const limitExceededError = comparableCount && locationKindPresent && comparableLocations[kind].length === locationsLimit && !locationIsInTheList;

  if (limitExceededError) {
    return { limitExceededError };
  } else {
    if (locationIsInTheList) {
      comparableLocations[kind].splice(comparableLocations[kind].indexOf(locationIdStr), 1);
      comparableLocations[kind].length === 0 && delete comparableLocations[kind];
    } else {
      bounceFunction && bounceFunction(locationId);
      comparableLocations[kind]
        ? comparableLocations[kind].unshift(locationIdStr)
        : comparableLocations[kind] = [locationIdStr];
    }

    localStorage.setItem(`my.comparable_lease_${siteKey}`, JSON.stringify(comparableLocations));
    handleComparableLocationsCounter(siteKey, !locationIsInTheList);

    return { comparableLocations };
  }
};

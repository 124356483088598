import React from 'react';
import cookie from 'react-cookies';
import ReactTooltip from 'react-tooltip';

import { updateComparables } from "../../../utils/ComparableLocations";
import Portal from '../Portal';
import Notification from '../Notification';
import { SvgIco } from "../Icons";

import './index.sass';


export class CompareIcon extends React.PureComponent {

  handleIsActive = () => {
    const { siteKey, locationId } = this.props;
    const retrievedComparableLocations = localStorage && localStorage.getItem(`my.comparable_lease_${siteKey}`);
    const comparableLocations = JSON.parse(retrievedComparableLocations) || {};
    let allIds = [];

    for (let prop in comparableLocations) {
      if (comparableLocations.hasOwnProperty(prop)) {
        allIds = allIds.concat(comparableLocations[prop]);
      }
    }
    const isActive = allIds.indexOf(`${locationId}`) >= 0;

    this.setState({ isActive });
  };

  componentDidMount() {
    this.handleIsActive();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { comparableLimitExceededError, showLimitExceedMessage } = this.state;

    comparableLimitExceededError && comparableLimitExceededError !== prevState.comparableLimitExceededError && this.showLimitExceedMessage();
    this.props.comparableLocations !== prevProps.comparableLocations && this.handleIsActive();
    showLimitExceedMessage && showLimitExceedMessage !== prevState.showLimitExceedMessage && document.addEventListener('click', this.handleClickOutside);
  };

  handleClickOutside = () => {
    const { showLimitExceedMessage } = this.state;

    document.removeEventListener('click', this.handleClickOutside);
    showLimitExceedMessage && this.hideLimitExceedMessage();
  }

  constructor(props) {
    super(props);
    this.typingTimer = null;

    this.state = {
      policyIsAccepted: cookie.load('cookie_consent_shown'),
      comparableLimitExceededError: false,
      showLimitExceedMessage: false,
      descriptionPopup: false,
      isActive: false,
    };
  }


  handleClick = () => {
    const { comparableLimitExceededError, isActive } = this.state;
    const { updateComparableLocations, siteKey, locationId, hideTooltip, kind } = this.props;

    if (comparableLimitExceededError && !isActive) {
      this.showLimitExceedMessage();
    } else {
      const showInfoPopup = !(localStorage && localStorage.getItem(`my.comparable_message_shown`));
      const newComparablesList = updateComparables(locationId, siteKey, kind, this.bounce);

      showInfoPopup &&
      this.setState({ descriptionPopup: true }, () => {
        clearTimeout(this.typingTimer);
        localStorage && localStorage.setItem(`my.comparable_message_shown`, 'true')
        this.typingTimer = setTimeout(() => this.setState({ descriptionPopup: false }), 6000);
      });

      if (newComparablesList.limitExceededError) {
        this.setState({ comparableLimitExceededError: !this.state.comparableLimitExceededError });
      } else {
        const { comparableLocations } = newComparablesList;
        const kindPresent = comparableLocations[kind] && comparableLocations[kind].length;

        this.setState({ isActive: kindPresent && comparableLocations[kind].indexOf(`${locationId}`) >= 0 });
        updateComparableLocations && updateComparableLocations(comparableLocations);
      }

      !hideTooltip && ReactTooltip.hide(this[`compareIcon${locationId}`]);
    }
  };


  bounce = () => {
    const iconWrapper = document.getElementById(`iconWrapper${this.props.locationId}`);

    iconWrapper.classList.add('bounce');
    setTimeout(() => iconWrapper.classList.remove('bounce'), 1000);
  };


  showLimitExceedMessage = () =>
    this.setState({ showLimitExceedMessage: true }, () => {
      this.typingTimer = setTimeout(() => this.setState({ showLimitExceedMessage: false }), 6000);
    });


  hideLimitExceedMessage = () => {
    clearTimeout(this.typingTimer);
    this.setState({ showLimitExceedMessage: false });
  };


  closeDescriptionPopup = () => {
    clearTimeout(this.typingTimer);
    this.setState({ descriptionPopup: false });
    localStorage && localStorage.setItem(`my.comparable_message_shown`, 'true');
  };


  render() {

    const { descriptionPopup, isActive, policyIsAccepted, showLimitExceedMessage } = this.state;
    const { indentation, locationId, notificationWrapperId, tooltipPosition, withLabel } = this.props;
    const tooltipText = isActive ? I18n.t('compare.icon.tooltip_text.remove') : I18n.t('compare.icon.tooltip_text.add');
    const limitExceedMessage = I18n.t('compare.icon.tooltip_text.limit_exceed_message');


    return (
      <div className="compare-icon">
        { withLabel
          ?
          <div className="compare-icon__with-label" onClick={this.handleClick}>
            <div className="compare-icon__wrapper compare-icon__wrapper--with-label" id={`iconWrapper${locationId}`}>
              <SvgIco name="compare" size={22} className={isActive ? 'active' : ''}/>
              { isActive &&
                <div className="compare-icon__check-wrapper">
                  <SvgIco name="check" size={7} />
                </div>
              }
            </div>
            <span className="compare-icon__label">
              { I18n.t('compare.icon.tooltip_text.add') }
            </span>
          </div>
          :
          <div role="button" className="compare-icon__wrapper" id={`iconWrapper${locationId}`}>
            <div className="compare-icon__click-area"
                 role="button"
                 onClick={this.handleClick}
                 aria-labelledby="compare-icon"
                 style={indentation && {top: '32px'}}>
              <div className={`compare-icon__icon${ isActive ? ' active' : '' }`}
                   data-tip={ tooltipText }
                   data-class="compare-icon__tooltip"
                   data-for={'compareIconTooltip' + locationId}
                   ref={ el => this[`compareIcon${ locationId }`] = el }>
                  <SvgIco name="compare" size={18} />
              </div>

              { isActive &&
                <div className="compare-icon__check-wrapper">
                  <SvgIco name="check" size={6} />
                </div>
              }

              { <ReactTooltip place={ tooltipPosition } effect='solid' id={'compareIconTooltip' + locationId}/> }
            </div>
          </div>
        }

        <Portal>
          <div ref={el => this.notificationWrapper = el}
               className="notifications-wrapper"
               id={notificationWrapperId}
               role="button"
               style={{ display: 'flex', justifyContent: 'center' }}>

            <Notification
              message={I18n.t('compare.icon.tooltip_text.was_added')}
              visible={descriptionPopup}
              customClass={!policyIsAccepted ? 'with-policy-block bottom-center' : 'bottom-center'}
              handleClose={this.closeDescriptionPopup} />

            <Notification
              message={limitExceedMessage}
              visible={showLimitExceedMessage}
              customClass="dark bottom-center"
              handleClose={this.hideLimitExceedMessage} />
          </div>
        </Portal>

      </div>
    )
  }
}


export default CompareIcon;

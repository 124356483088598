import React from 'react';
import disposalBadge from '../../src/images/icons/disposal-icon.svg';

import './Badges.sass';


export const SpecialOffer = ({ root }) =>
  <div className={`special-offer-badge special-offer-badge--${root}-view`}>
    <span>{ I18n.t('generic.special_offer') }</span>
  </div>;

export const NewBadge = ({ root }) =>
  <div className={`new-badge new-badge--${root}-view`}>
    <span>{ I18n.t("apps.lb_showroom.showroom.list.new_item_label") }</span>
  </div>;

export const RentedOutBadge = ({ label, root, month, sale }) =>
  <div className={`stripe-badge stripe-badge--${root}-view rented-out`}>
    { label ||
      ( sale
        ? I18n.t('activerecord.attributes.location.states.sold_out')
        : `${ I18n.t('activerecord.attributes.location.states.rented_out', {count: month ? 1 : 0}) } ${ month || '' }`
      )
    }
  </div>;

export const AnonymousBadge = ({ root }) =>
  <div className={`stripe-badge stripe-badge--${root}-view anonymous`}>
    { I18n.t('generic.anonymous_location.detailed.label') }
  </div>;

export const CrawledBadge = ({ root, missingImagesUrl }) => {
  const bottomText = root !== 'detail'
                     ? I18n.t('generic.photos_are_not_available')
                     : I18n.t('generic.parsed_copyright');
  const readMoreLink = root === 'detail'
                     ? <span>
                         <a href={missingImagesUrl} target="_blank">
                           { I18n.t('generic.read_more_about_scraped') }
                         </a>
                       </span>
                     : null;

  return (
    <div className={`stripe-badge stripe-badge--${root}-view crawled`}>
      <div className="top-wrapper">
        { I18n.t('generic.parsed_location') }
      </div>
      <div className="bottom-wrapper">
        <p className="bottom-wrapper__content">
          { bottomText }&nbsp;
          { readMoreLink }
        </p>
      </div>
    </div>
    )
};

export const DisposalBadge = ({ root }) =>
  <div className={`description-badge description-badge--${root}-view disposal`}>
    <img src={disposalBadge} alt="disposal" className="lazyload" data-sizes="auto" />
    <p>{ I18n.t('generic.disposal') }</p>
  </div>;
